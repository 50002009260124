export default [
    {
        displayText: `I don't think deli meat should be a lot of things but here we are.`,
        soundClip: 'seanDeliMeat',
        new: true,
    },
    {
        displayText: `Two Keagans!`,
        soundClip: 'seanTwoKeegans',
    },
    {
        displayText: `He jumped in the air and did a somersault, and killed me with his bird foot. `,
        soundClip: 'seanBirdfoot',
        new: true,
    },
    {
        displayText: `Boneees`,
        soundClip: 'seanBones',
        new: true,
    },
    {
        displayText: `Waitaminiute- this guy POISONED me.`,
        soundClip: 'seanPoisonedMe',
        new: true,
    },
    {
        displayText: `I think scarlet rot is just, uh, high cholesterol.`,
        soundClip: 'seanScarletRot',
        new: true,
    },
    {
        displayText: `Where do I go now? Ahaie ee ai ah wah wah wah.`,
        soundClip: 'seanWhereGo',
        new: true,
    },
]