export default [
    {
        displayText: `Butthole.`,
        soundClip: 'butthole'
    },
    {
        displayText: `Don't be mean to me!`,
        soundClip: 'dontBeMeanToMe'
    },
    {
        displayText: `Don't get greedy, DON'T GET GREEDY.`,
        soundClip: 'dontGetGreedy'
    },
    {
        displayText: `Don't kill me. Let me kill you, you asshole.`,
        soundClip: 'dontKillMe'
    },
    {
        displayText: `Don't kill me. Fuck you.`,
        soundClip: 'dontKillMeFuckYou'
    },
    {
        displayText: `Dumbass fukken goat.`,
        soundClip: 'dumbassGoat'
    },
    {
        displayText: `FuCK`,
        soundClip: 'fuck'
    },
    {
        displayText: `Gang gang gang gaaaenggg.`,
        soundClip: 'ganggangganggang'
    },
    {
        displayText: `Goblin boyz... :)`,
        soundClip: 'goblinBoys'
    },
    {
        displayText: `I'm in a swamp.`,
        soundClip: 'imInASwamp'
    },
    {
        displayText: `JAKEY BOYYY!`,
        soundClip: 'jakeyBoy'
    },
    {
        displayText: `This guy's gonna boof me. (oh shit) [DIES]`,
        soundClip: 'boofMe',
    },
    {
        displayText: `Though, I will say this... um.`,
        soundClip: 'iWillSayThisSmack'
    },
    {
        displayText: `Which is a euphamism for a butthole by the way. (ok)`,
        soundClip: 'buttholeEuphamism'
    },
    {
        displayText: `Lil bItCh.`,
        soundClip: 'lilbitch'
    },
    {
        displayText: `So I need to go kill this. tha. the. tha milfman? (Say again?)... Milf.`,
        soundClip: 'milfman'
    },
    {
        displayText: `Oh fuck oh fuck.`,
        soundClip: 'ohFuckOhFuck'
    },
    {
        displayText: `Oh. Fuck you, you..`,
        soundClip: 'ohFuckYou'
    },
    {
        displayText: `(Keagan scats.)`,
        soundClip: 'scat'
    },
    {
        displayText: `Shadowbait deez balls (magic sound)`,
        soundClip: 'shadowbait'
    },
    {
        displayText: `Suck mY aSS`,
        soundClip: 'suckMyAss'
    },
    {
        displayText: `Suck my ass, Margaret!`,
        soundClip: 'suckMyAssMargaret'
    },
    {
        displayText: `uh.. swamp testicles.`,
        soundClip: 'swampTesticles'
    },
    {
        displayText: `Ugh. Don't take your sword out you assHoLe.`,
        soundClip: 'swordOutAsshole'
    },
    {
        displayText: `Oh I did the wrong thing. I made the wrong choice, I made the wrong choice.`,
        soundClip: 'wrongChoice'
    },
    {
        displayText: `You have to EARN it.`,
        soundClip: 'youHaveToEarnIt'
    },
    {
        displayText: `You've been a baad boy (heh)`,
        soundClip: 'youveBeenABadBoy'
    },
    {
        displayText: `Ya never know when you're gonna need a... a Keagan quote.`,
        soundClip: 'keaganQuote',
    },
    {
        displayText: `That's what she said.`,
        soundClip: 'thatsWhatSheSaid',
    },
    {
        displayText: `Ohhh wah, you dirty daaawg. (heh)`,
        soundClip: 'dirtyDog',
    },
    {
        displayText: `I can't do anything but blame myself.`,
        soundClip: 'blameMyself',
    },
    {
        displayText: `Dawng`,
        soundClip: 'dong',
    },
    {
        displayText: `Haaaaaaaie`,
        soundClip: 'haiee',
    },
    {
        displayText: `He's getting STRONGER.`,
        soundClip: 'hesGettingStronger',
    },
    {
        displayText: `Keagan sings Old Town Road`,
        soundClip: 'horse',
    },
    {
        displayText: `How you like that, bitch.`,
        soundClip: 'howYouLikeThatBitch',
    },
    {
        displayText: `Laughs in falsetto`,
        soundClip: 'laughsInFalsetto',
    },
    {
        displayText: `Noodles and oatmeal for my snacks.`,
        soundClip: 'oatmeal',
    },
    {
        displayText: `I didn't know you rolled that way but... you know what, that's ya know.`,
        soundClip: 'rollThatWay',
    },
    {
        displayText: `Duh, what the fuck?! This guy's doing cartwheels.`,
        soundClip: 'theFuckCartwheels',
    },
]