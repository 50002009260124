export default [
    {
        displayText: `Fort nite.`,
        soundClip: 'jakeFortnites'
    },
    {
        displayText: `Get outta me swamp.`,
        soundClip: 'jakeOuttaMeSwamp'
    },
    {
        displayText: `It helps bring the wife into it.`,
        soundClip: 'jakeWifeIntoIt'
    },
    {
        displayText: `You freaking butthole.`,
        soundClip: 'jakeButthole',
        new: true,
    },
]